@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

body, html {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}

html{
    --line-color: #e2e8f0,
}

body{
    background-color:ghostwhite;
}

.borderBlack {
    @apply border border-black/10
}